import { Alert, AlertDescription, AlertIcon, Button, Card, Flex, Icon, Input, Text, VStack } from '@chakra-ui/react'
import { LuFileLock2 } from 'react-icons/lu'

interface PasswordProps {
  isInvalidPassword: boolean
  setIsInvalidPassword: (isInvalidPassword: boolean) => void
  password: string | undefined
  setPassword: (password: string) => void
  onSubmit: () => void
}

export const PasswordPage: React.FC<PasswordProps> = ({
  isInvalidPassword,
  setIsInvalidPassword,
  password,
  setPassword,
  onSubmit,
}) => {
  return (
    <Flex w="100vw" h="100vh" bg="rgb(0,0,0,0.25)" alignItems="center" justifyContent="center">
      <Card variant="outline" bg="white" p="8">
        <VStack>
          <Icon as={LuFileLock2} boxSize={10} color="gray.400" />
          <Text fontSize="xl" fontWeight="semibold" color="gray.600" pt="2">
            This is a password protected form
          </Text>
          <Text fontSize="sm" color="gray.500">
            Please enter the password to view and fill out this form.
          </Text>
          {isInvalidPassword && (
            <Alert status="error" mb="1" rounded="md">
              <AlertIcon />
              <AlertDescription>Invalid password. Please try again.</AlertDescription>
            </Alert>
          )}
          <Input
            type="password"
            placeholder="* * * * * * * *"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            colorScheme="blue"
            p="5"
            mt="2"
            onClick={() => {
              setIsInvalidPassword(false)
              onSubmit()
            }}
          >
            Continue
          </Button>
        </VStack>
      </Card>
    </Flex>
  )
}
