import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { FormFieldPages, FormFieldTypes } from '../interfaces'

interface GoogleMapsContextType {
  google: any
}

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const GoogleMapsContext = createContext<GoogleMapsContextType | undefined>(undefined)

export const GoogleMapsProvider: React.FC<PropsWithChildren<{ pages: FormFieldPages }>> = ({ children, pages }) => {
  const [hasAddressField, setHasAddressField] = useState<boolean>(false)
  const [google, setGoogle] = useState<any>(null)

  useEffect(() => {
    const allFormFields = Object.values(pages).flat()
    if (!hasAddressField && allFormFields.some(field => field.type === FormFieldTypes.Address)) {
      setHasAddressField(true)
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=initMap`
      script.async = true
      script.defer = true
      script.onload = () => {
        setGoogle(window.google)
      }
      document.body.appendChild(script)
    }
  }, [])

  return <GoogleMapsContext.Provider value={{ google }}>{children}</GoogleMapsContext.Provider>
}

export const useGoogleMaps = () => {
  const context = useContext(GoogleMapsContext)
  if (context === undefined) {
    throw new Error('useGoogleMaps must be used within a GoogleMapsProvider')
  }
  return context
}
