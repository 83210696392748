// eslint-disable-next-line @typescript-eslint/no-empty-function
export const EMPTY_FN = () => {}

export const TASK_DATA_GRID_COLUMNS = 12
export const GRID_ROW_HEIGHT = 80

export const MIN_FORM_WIDTH = '600px'

export const cols = {
  lg: TASK_DATA_GRID_COLUMNS,
  md: TASK_DATA_GRID_COLUMNS,
  sm: TASK_DATA_GRID_COLUMNS,
  xs: TASK_DATA_GRID_COLUMNS,
  xxs: TASK_DATA_GRID_COLUMNS,
}
