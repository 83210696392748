import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroupProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'

interface NumericInputFieldProps {
  formControlName: string
  label: string
  variant?: InputGroupProps['variant']
  labelTooltip?: string
  control: Control
  error?: string
  isRequired?: boolean
  isLabelHidden?: boolean
  hasNoOutline?: boolean
  isDisabled?: boolean
  isReadonly?: boolean
  isDynamicWidth?: boolean
  helperText?: string
  placeholder?: string
  step?: number
  min?: number
  max?: number
  overrideControlName?: string
  style: NonNullable<StyleOptions>
}

export const NumericInputField: React.FC<NumericInputFieldProps> = ({
  formControlName,
  variant = 'outlined',
  label,
  control,
  error,
  helperText,
  placeholder: placeholderValue,
  isRequired,
  isLabelHidden,
  hasNoOutline,
  isDisabled,
  isReadonly,
  isDynamicWidth,
  step,
  min,
  max,
  overrideControlName,
  style,
}) => {
  const { colors, inputs } = StyleUtils.getTheme(style)
  const placeholderColor = StyleUtils.getPlaceholderColor(colors.inputBgColor)
  const [displayedValue, setDisplayedValue] = useState<string | null | undefined>()
  const placeholder = placeholderValue || 'Input value'
  const { getTabIndex } = useFormAccessibility()

  return (
    <Controller
      name={overrideControlName ?? formControlName}
      control={control}
      render={({ field }) => {
        const inputWidth =
          isDynamicWidth && (field.value || displayedValue)?.length > 20
            ? `${Math.max((field.value?.length ?? displayedValue?.length ?? 0) * 9.5, 200)}px`
            : '100%'

        return (
          <FormControl
            isInvalid={!!error}
            isRequired={isRequired}
            minHeight={variant === 'unstyled' ? '41px' : undefined}
          >
            {!isLabelHidden && (
              <FormLabel display="flex" alignItems="center" gap="1" color={colors.labelColor}>
                {label}
                <span className="form__optional-indicator">
                  {!isRequired && inputs.hideRequiredAsterisk && '- Optional'}
                </span>
                {helperText && <InfoTip tooltipText={helperText} />}
              </FormLabel>
            )}

            <NumberInput
              {...field}
              variant={variant}
              step={step ?? 1}
              min={min ?? undefined}
              max={max ?? undefined}
              keepWithinRange={false}
              clampValueOnBlur={false}
              isDisabled={isDisabled || isReadonly}
              defaultValue={field?.value ?? ''}
              value={field?.value ?? ''}
              onChange={(valueString, valueNumber) => {
                if (isNaN(valueNumber)) {
                  setDisplayedValue(null)
                  field.onChange(null)
                } else {
                  setDisplayedValue(valueString)
                  field.onChange(valueNumber)
                }
              }}
              sx={{
                '& input': {
                  bg: `${colors.inputBgColor} !important}`,
                  color: colors.inputTextColor,
                },
              }}
            >
              <NumberInputField
                ref={field.ref}
                id={`unmand-input-${overrideControlName ?? formControlName}`}
                minHeight={variant === 'unstyled' ? '41px' : undefined}
                px={variant === 'unstyled' ? 2 : undefined}
                {...(hasNoOutline ? { border: 0 } : {})}
                placeholder={placeholder}
                tabIndex={getTabIndex(formControlName)}
                _placeholder={{
                  color: placeholderColor,
                  fontStyle: 'italic',
                }}
                borderColor={variant === 'outline' ? inputs.borderColor : undefined}
                borderRadius={variant === 'unstyled' ? 0 : inputs.borderRadius}
                _focusVisible={{
                  borderColor: colors.primaryColor,
                  boxShadow: `0 0 0 1px ${colors.primaryColor}`,
                }}
                _focus={{
                  borderColor: colors.primaryColor,
                }}
                width={inputWidth}
              />
              <NumberInputStepper>
                <NumberIncrementStepper color={colors.inputTextColor} borderColor={inputs.borderColor} />
                <NumberDecrementStepper color={colors.inputTextColor} borderColor={inputs.borderColor} />
              </NumberInputStepper>
            </NumberInput>
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
        )
      }}
    />
  )
}
