import { Flex, Icon, Text, VStack } from '@chakra-ui/react'
import { LuLock } from 'react-icons/lu'

export interface DisabledPageProps {
  messageTitle: string
  messageDescription: string
}

export const DisabledPage: React.FC<DisabledPageProps> = ({ messageTitle, messageDescription }) => {
  return (
    <Flex w="100vw" h="100vh" bg="gray.100" alignItems="center" justifyContent="center">
      <VStack>
        <Icon as={LuLock} boxSize={10} color="gray.400" />
        <Text fontSize="xl" fontWeight="semibold" color="gray.600">
          {messageTitle}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {messageDescription}
        </Text>
      </VStack>
    </Flex>
  )
}
