import { ListItem, OrderedList, Text } from '@chakra-ui/react'
import jsonpath from 'jsonpath'
import { isNil, zipObject } from 'lodash'
import { AddressFieldKeys, BasicField, FlatData, FormField, FormFieldPages, FormFieldTypes } from '../interfaces'
import { FormBuilderUtils } from './form-builder.utils'

export const updateDataByJsonpaths = (
  fieldValueMap: { [fieldKey: string]: any },
  fieldPages: FormFieldPages,
  data: FlatData,
  onShowWarning?: (warningTitle: string, warningMsg: JSX.Element) => void,
): {
  [fieldJsonPath: string]: any
} => {
  const updatedData = { ...data }

  const fields: FormField[] = Object.values(fieldPages).flatMap(array => array)

  Object.keys(fieldValueMap).forEach((fieldKey: string) => {
    const field = fields.find(f => f.key === fieldKey)

    if (
      !field ||
      field.type === FormFieldTypes.Divider ||
      field.type === FormFieldTypes.Header ||
      field.type === FormFieldTypes.StaticMarkdown ||
      field.type === FormFieldTypes.StaticImage
    ) {
      return
    }

    try {
      const existingPath = jsonpath.value(updatedData, field.jsonPath)
      let fieldValue = fieldValueMap[fieldKey]

      if (field.type === FormFieldTypes.JSON) {
        fieldValue = JSON.parse(fieldValue)
      }

      if (field.type === FormFieldTypes.IconPicker) {
        fieldValue = field.outputAsBoolean
          ? zipObject(
              field.icons.map(({ jsonPath }) => jsonPath),
              field.icons.map(({ label }) => fieldValue.includes(label)),
            )
          : fieldValue
      }

      if (field.type === FormFieldTypes.Table) {
        fieldValue = FormBuilderUtils.getParsedTableValue(field, fieldValue)
      }

      if (field.type === FormFieldTypes.Address) {
        const setAddressFieldValue = (addressFieldKey: string, addressJsonPath: string) => {
          const value = fieldValueMap[addressFieldKey]

          createNestedObjectByJsonPath(updatedData, addressJsonPath, value)
        }

        setAddressFieldValue(`${fieldKey}-${AddressFieldKeys.STREET_ADDRESS}`, field.streetAddressJsonPath)
        setAddressFieldValue(`${fieldKey}-${AddressFieldKeys.STREET_ADDRESS_2}`, field.streetAddressLine2JsonPath)
        setAddressFieldValue(`${fieldKey}-${AddressFieldKeys.CITY}`, field.cityJsonPath)
        setAddressFieldValue(`${fieldKey}-${AddressFieldKeys.STATE_OR_PROVINCE}`, field.stateProvinceJsonPath)
        setAddressFieldValue(`${fieldKey}-${AddressFieldKeys.COUNTRY}`, field.countryJsonPath)
        setAddressFieldValue(`${fieldKey}-${AddressFieldKeys.POSTAL_ZIP_CODE}`, field.postalCodeJsonPath)
      }

      if (!isNil(existingPath)) {
        jsonpath.value(updatedData, field.jsonPath, fieldValue)
      } else {
        /**
         * Creates an object if it does not exist on form data.
         */
        createNestedObjectByJsonPath(updatedData, field.jsonPath, fieldValue)
      }
    } catch (e) {
      const hasJsonColumn =
        field.type === FormFieldTypes.Table && field.columns.some(c => c.columnType === FormFieldTypes.JSON)

      /**
       * Catches cases like $.does_not_exist[0].property
       */
      onShowWarning?.(
        `Value for ${field.name} was not saved.`,
        <>
          <Text>Possible causes:</Text>
          <OrderedList>
            <ListItem>({field.jsonPath}) is an invalid jsonpath key, please contact this form&apos;s owner.</ListItem>

            {field.type === FormFieldTypes.JSON && (
              <ListItem>Double-check if the value you entered for this field is a valid JSON.</ListItem>
            )}

            {hasJsonColumn && <ListItem>Double-check the JSON column inputs if they are a valid JSON.</ListItem>}
          </OrderedList>
        </>,
      )
      console.error(
        `Failed to parse jsonpath for Key[${field.key}] Jsonpath[${(field as BasicField).jsonPath}] Name[${
          (field as BasicField).name
        }]`,
        e,
      )
    }
  })

  return updatedData
}

const getDotNotationFromJsonPath = (jsonPath: string): string => {
  const bracketNotationRegex = /\["([^"]*)"\]/g // Matches ["*"] where * is anything
  let result = jsonPath.replace('$.', '').replace('$', '')

  result = result.replace(bracketNotationRegex, (_, match) => `.${match}`)

  if (result.startsWith('.')) {
    result = result.substring(1)
  }

  return result
}

/**
 * Given this path: obj.list[0].text
 * It will create the appropriate object: { obj: { list: [{ text: "" }] }
 *
 * Function description:
 *    This is a nested traversal function that relies on object references.
 *    a.b.c.d -> first iteration creates (a: {}), moves the pointer reference to "a" and creates within "a" -> (b: {}),
 *    moves the pointer to "b" and creates (c: {}) and so on.
 */
const createNestedObjectByJsonPath = (data: { [fieldJsonPath: string]: any }, jsonPathRaw: string, value: any) => {
  const jsonPath = getDotNotationFromJsonPath(jsonPathRaw)
  const keys = jsonPath.split('.')
  let currentObj = data

  for (let i = 0; i < keys.length - 1; i++) {
    const isArrayKey = keys[i].includes('[')

    if (isArrayKey) {
      const splitKey = keys[i].split('[')
      const cleanKey = splitKey[0]
      const arrayIdx = parseInt(splitKey[1].split(']')[0])
      currentObj[cleanKey] = currentObj[cleanKey] ?? []
      currentObj[cleanKey][arrayIdx] = currentObj[cleanKey][arrayIdx] ?? {}

      for (let j = 0; j < arrayIdx; j++) {
        currentObj[cleanKey][j] = currentObj[cleanKey][j] ?? {}
      }

      currentObj = currentObj[cleanKey][arrayIdx]
    } else {
      currentObj[keys[i]] = currentObj[keys[i]] || {}
      currentObj = currentObj[keys[i]]
    }
  }

  currentObj[keys[keys.length - 1]] = value ?? null
}

const updateFormDataByJson = (data: { [key: string]: string }, formFields: FormField[]) => {
  const updatedFormData = {}

  formFields
    .filter(f => (f as BasicField).jsonPath)
    .forEach((f: FormField) => {
      try {
        const value = jsonpath.value(data, (f as BasicField).jsonPath)

        if (value !== undefined && value !== null) {
          updatedFormData[f.key] = value
        }

        if (
          (f.type === FormFieldTypes.Checkbox || f.type === FormFieldTypes.YesNo || f.type === FormFieldTypes.Switch) &&
          !isNil(value)
        ) {
          updatedFormData[f.key] = value.toString() === 'true'
        }

        if (f.type === FormFieldTypes.IconPicker) {
          updatedFormData[f.key] = f.outputAsBoolean
            ? Object.entries(value)
                /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
                .filter(([_, value]) => value === true)
                /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
                .map(([key, _]) => f.icons.find(({ jsonPath }) => jsonPath === key)?.label)
            : value
        }

        if (f.type === FormFieldTypes.Address) {
          updatedFormData[`${f.key}-${AddressFieldKeys.STREET_ADDRESS}`] = jsonpath.value(data, f.streetAddressJsonPath)
          updatedFormData[`${f.key}-${AddressFieldKeys.STREET_ADDRESS_2}`] = jsonpath.value(
            data,
            f.streetAddressLine2JsonPath,
          )
          updatedFormData[`${f.key}-${AddressFieldKeys.CITY}`] = jsonpath.value(data, f.cityJsonPath)
          updatedFormData[`${f.key}-${AddressFieldKeys.STATE_OR_PROVINCE}`] = jsonpath.value(
            data,
            f.stateProvinceJsonPath,
          )
          updatedFormData[`${f.key}-${AddressFieldKeys.COUNTRY}`] = jsonpath.value(data, f.countryJsonPath)
          updatedFormData[`${f.key}-${AddressFieldKeys.POSTAL_ZIP_CODE}`] = jsonpath.value(data, f.postalCodeJsonPath)
        }
      } catch (e) {
        console.warn(
          `Failed to parse jsonpath for Key[${f.key}] Jsonpath[${(f as BasicField).jsonPath}] Name[${
            (f as BasicField).name
          }] `,
        )
      }
    })

  return updatedFormData
}

const prependParentJsonpath = (parentJsonPath: string | undefined, jsonPath: string): string => {
  if (!parentJsonPath) {
    return jsonPath
  }

  return `${parentJsonPath}[0].${jsonPath.replace('$.', '').replace('$', '')}`
}

const scrollFieldIntoView = (fieldId: string) => {
  let fieldElement = document.getElementById(`formfield-${fieldId}`)

  if (!fieldElement) {
    fieldElement = document.querySelector('[name="' + fieldId + '"]')
  }

  if (fieldElement) {
    fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  } else {
    console.warn(`Element not found: ${fieldId}`)
  }
}

const findPageGuidByFieldGuid = (fieldGuid: string, fieldPages: FormFieldPages) =>
  Object.keys(fieldPages).find(key => fieldPages[key].some((item: FormField) => item.guid === fieldGuid))

export const FormRendererUtils = {
  updateDataByJsonpaths,
  updateFormDataByJson,
  prependParentJsonpath,
  createNestedObjectByJsonPath,
  scrollFieldIntoView,
  findPageGuidByFieldGuid,
}
