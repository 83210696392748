import { Box, Card, FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react'
import { InfoTip, LabelValue } from '@unmand-systems/components'
import { chakraComponents, GroupBase, MenuListProps, MultiValue, Select, SingleValue } from 'chakra-react-select'
import React, { ComponentType } from 'react'
import { Control, Controller } from 'react-hook-form'
import { FixedSizeList as List } from 'react-window'
import tinycolor from 'tinycolor2'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { useFormState } from '../../context/FormStateContext'
import { StyleOptions } from '../../interfaces'
import { GroupedPageUtils, StyleUtils } from '../../utils'
import { customSelectComponents } from '../core/CustomSelect'

interface SelectFieldProps {
  formControlName: string
  label: string
  labelTooltip?: string
  control: Control
  error?: string
  isRequired?: boolean
  isLabelHidden?: boolean
  isDisabled?: boolean
  isReadonly?: boolean
  options?: string[]
  isMultiSelect: boolean
  helperText?: string
  placeholder?: string
  maxOptions?: number
  overrideControlName?: string
  groupedPageRefId?: string
  hasNoOutline?: boolean
  style: NonNullable<StyleOptions>
}

export const SelectField: React.FC<SelectFieldProps> = ({
  formControlName,
  label,
  control,
  error,
  isReadonly,
  isLabelHidden,
  isDisabled,
  isRequired,
  options,
  isMultiSelect,
  helperText,
  placeholder,
  maxOptions = undefined,
  overrideControlName,
  groupedPageRefId,
  hasNoOutline,
  style,
}) => {
  const { colors, inputs } = StyleUtils.getTheme(style)

  const placeholderColor = StyleUtils.getPlaceholderColor(colors.inputBgColor)
  const { getTabIndex } = useFormAccessibility()
  const {
    fieldPages,
    pages,
    formMethods: { getValues },
  } = useFormState()

  const getDropdownOptions = () => {
    let optionList: string[] = []

    if (groupedPageRefId) {
      const pageId = groupedPageRefId
      const page = pages.find(p => p.guid === pageId)
      const fieldPageIndexes = Array.from(
        { length: GroupedPageUtils.getListIndexCount(fieldPages[pageId]) },
        (_, idx) => idx,
      )
      optionList = fieldPageIndexes.map(index =>
        GroupedPageUtils.getTabLabel(page!, index, fieldPages[pageId], getValues),
      )
    } else {
      optionList = options ?? []
    }

    return optionList
  }

  const dropdownOptions = getDropdownOptions().map(option => ({ label: option, value: option }))

  return (
    <Controller
      name={overrideControlName ?? formControlName}
      control={control}
      render={({ field }) => (
        <FormControl
          isInvalid={!!error}
          isRequired={isRequired}
          display="flex"
          flexDir="column"
          h={isMultiSelect ? '100%' : 'auto'}
        >
          {!isLabelHidden && (
            <FormLabel display="flex" alignItems="center" gap="1">
              {label}
              <span className="form__optional-indicator">
                {!isRequired && inputs.hideRequiredAsterisk && '- Optional'}
              </span>
              {helperText && <InfoTip tooltipText={helperText} />}
            </FormLabel>
          )}
          <Select
            {...field}
            id={`unmand-input-${overrideControlName ?? formControlName}`}
            tabIndex={getTabIndex(formControlName)}
            isClearable={isReadonly || isDisabled ? false : true}
            menuPortalTarget={document.body}
            value={
              isMultiSelect
                ? dropdownOptions?.filter((v: LabelValue<string>) => (field?.value ?? []).includes(v.value))
                : field.value
                ? dropdownOptions?.find((v: LabelValue<string>) => v.value === field.value)
                : null
            }
            options={dropdownOptions}
            isMulti={isMultiSelect}
            isReadOnly={isReadonly}
            isDisabled={isDisabled}
            isOptionDisabled={() => {
              if (maxOptions === null) {
                return false
              } else {
                return isMultiSelect && maxOptions !== undefined && field.value?.length >= maxOptions
              }
            }}
            components={{
              ...customSelectComponents,
              MenuList: dropdownOptions.length > 100 ? MenuList : chakraComponents.MenuList,
              Option:
                dropdownOptions.length > 100
                  ? ({ children, ...props }) => (
                      <chakraComponents.Option {...props}>
                        <Box isTruncated>{children}</Box>
                      </chakraComponents.Option>
                    )
                  : chakraComponents.Option,
            }}
            placeholder={
              <Text color={placeholderColor} fontStyle="italic">
                {isReadonly ? 'Not Available' : placeholder || 'Select value'}
              </Text>
            }
            onChange={selectedOption => {
              if (isMultiSelect) {
                field.onChange((selectedOption as MultiValue<LabelValue<string>>)?.map(option => option.value) ?? null)
              } else {
                field.onChange((selectedOption as SingleValue<LabelValue<string>>)?.value ?? null)
              }
            }}
            chakraStyles={{
              control: provided => ({
                ...provided,
                height: isMultiSelect ? '100%' : 'auto',
                bg: colors.inputBgColor,
                color: colors.inputTextColor,
                borderRadius: hasNoOutline ? undefined : inputs.borderRadius,
                borderColor: hasNoOutline ? undefined : inputs.borderColor,
                border: hasNoOutline ? undefined : inputs.variant === 'filled' ? undefined : '1px solid',
                _focusVisible: {
                  borderColor: colors.primaryColor,
                  boxShadow: `0 0 0 1px ${colors.primaryColor}`,
                },
                _focus: {
                  borderColor: colors.primaryColor,
                },
              }),
              container: provided => ({
                ...provided,
                flex: 1,
                '& > div': {
                  alignItems: isMultiSelect ? 'flex-start' : 'initial',
                },
              }),
              valueContainer: provided => ({
                ...provided,
                paddingTop: isMultiSelect ? '8px' : 'initial',
                textAlign: 'left',
              }),
              placeholder: provided => ({
                ...provided,
                fontStyle: 'italic',
                textAlign: 'left',
              }),
              option: (provided, { isSelected, isFocused }) => {
                return {
                  ...provided,
                  color: 'gray.900',
                  bg: isSelected
                    ? `${colors.primaryColor} !important`
                    : isFocused
                    ? `${tinycolor(colors.primaryColor).setAlpha(0.2).toString()}`
                    : 'white',
                  '&:active': {
                    bg: tinycolor(colors.primaryColor).setAlpha(0.4).toString(),
                  },
                }
              },
            }}
          />
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
      )}
    />
  )
}

const height = 36.8

const MenuList: ComponentType<
  MenuListProps<{ label: string; value: string }, boolean, GroupBase<{ label: string; value: string }>>
> = props => {
  const { options, maxHeight, getValue, children } = props
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height
  return (
    <Card overflow="hidden">
      <List
        width={'100%'}
        height={maxHeight}
        itemCount={React.Children.count(children)}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ style, index }) => (
          <div style={style} key={index}>
            {children?.[index]}
          </div>
        )}
      </List>
    </Card>
  )
}
