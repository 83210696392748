import { cloneDeep } from 'lodash'
import {
  APIFormField,
  APIFormFieldPages,
  AddressFieldKeys,
  FormField,
  FormFieldPages,
  FormFieldTypes,
  GroupedInputsField,
  TableField,
} from '../interfaces'

/*
 * Converts an APIFormField => FormField
 */
const convertToFormField = (builderField: APIFormField): FormField => {
  const formField: FormField = {
    ...builderField.config,
    guid: builderField.guid,
    type: builderField.component,
    key: builderField.guid,
    jsonPath: builderField.jsonpath,
    name: builderField.name,
    layout: builderField.layout,
    listIndex: builderField.listIndex,
  }

  return formField
}

const getParsedTableValue = (field: TableField, fieldValue: Record<string, any>[]): Record<string, any>[] => {
  const updatedValues = cloneDeep(fieldValue)
  const jsonColumnKeys = field.columns.filter(c => c.columnType === FormFieldTypes.JSON).map(c => c.columnName)

  try {
    updatedValues.forEach((updatedValue, idx) => {
      jsonColumnKeys.forEach(key => {
        const val = getValueFromNestedObject(key, fieldValue[idx])
        updatedValue = populateNestedObject(key, updatedValue, val ? JSON.parse(val) : undefined)
      })
    })
  } catch (e) {
    console.error(e)
    throw Error(`Invalid JSON input for field ${field.key} - ${field.name}`)
  }

  return updatedValues
}

const getParsedGroupedInputsValue = (
  field: GroupedInputsField,
  fieldValue: Record<string, any>[],
): Record<string, any>[] => {
  const updatedValues = cloneDeep(fieldValue)
  const jsonColumnKeys = field.fields.map(c => c.fieldName)

  try {
    updatedValues.forEach(updatedValue => {
      jsonColumnKeys.forEach(key => {
        updatedValue[key] = null
      })
    })
  } catch (e) {
    console.error(e)
    throw Error(`Invalid JSON input for field ${field.key} - ${field.name}`)
  }

  return updatedValues
}

/**
 * Takes in a dot-notation key like parentKey.nestedKey and creates a nested object based on this key
 * e.g. key = "parent.child", obj = {}, newValue = "Hello World" ---- returns ----> { parent: { child: "Hello World" }}
 */
const populateNestedObject = (keys: string, obj: Record<string, any>, newValue: any): Record<string, any> => {
  const keyParts = keys.split('.')
  let currentObj = obj

  for (let i = 0; i < keyParts.length - 1; i++) {
    const key = keyParts[i]
    currentObj[key] = currentObj[key] || {}
    currentObj = currentObj[key]
  }

  const finalKey = keyParts[keyParts.length - 1]
  currentObj[finalKey] = newValue

  return currentObj
}

/**
 * Takes in a dot-notation key like parentKey.nestedKey and fetches the value from an object
 * e.g. obj = { parent: { child: "Hello World" }}, key = "parent.child" ---- returns ----> "Hello World"
 */
const getValueFromNestedObject = (keys: string, obj: Record<string, any>): any => {
  const keyParts = keys.split('.')
  let value: any = obj

  for (const key of keyParts) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key]
    } else {
      value = undefined
      break
    }
  }

  return value
}

const sortedByPosition = (fields: FormField[]): FormField[] => {
  return fields.sort((a, b) => {
    const aLayout = a.layout
    const bLayout = b.layout

    if (aLayout.y === bLayout.y) {
      return aLayout.x - bLayout.x
    }
    return aLayout.y - bLayout.y
  })
}

const assignTabIndexes = (fields: FormField[]): FormField[] =>
  sortedByPosition(fields).map((item, index) => {
    return { ...item, tabIndex: index }
  })

const convertFormPages = (page: APIFormFieldPages): FormFieldPages => {
  const convertedPages: FormFieldPages = {}

  Object.keys(page).forEach(pageKey => {
    convertedPages[pageKey] = page[pageKey].map(convertToFormField)
  })

  return convertedPages
}

const getAddressKeys = (key: string): string[] => {
  return [
    key,
    `${key}-${AddressFieldKeys.STREET_ADDRESS}`,
    `${key}-${AddressFieldKeys.STREET_ADDRESS_2}`,
    `${key}-${AddressFieldKeys.CITY}`,
    `${key}-${AddressFieldKeys.STATE_OR_PROVINCE}`,
    `${key}-${AddressFieldKeys.COUNTRY}`,
    `${key}-${AddressFieldKeys.POSTAL_ZIP_CODE}`,
  ]
}

const getRequiredAddressKeys = (key: string): string[] => {
  return [
    `${key}-${AddressFieldKeys.STREET_ADDRESS}`,
    `${key}-${AddressFieldKeys.CITY}`,
    `${key}-${AddressFieldKeys.STATE_OR_PROVINCE}`,
    `${key}-${AddressFieldKeys.COUNTRY}`,
    `${key}-${AddressFieldKeys.POSTAL_ZIP_CODE}`,
  ]
}

function substituteFormulaFields(
  formula: string,
  formFields: FormField[],
  values: Record<string, any>,
): {
  formula: string
  fieldGuids: string[]
} {
  const pattern = /\{\{([0-9a-zA-Z\-]+)\}\}/g
  const parts = formula.split(pattern).filter(Boolean)

  const res = {
    formula: parts
      .map(part => {
        if (formFields.find(f => f.guid === part)) {
          return values[part as keyof typeof values]
        } else {
          return part
        }
      })
      .join(''),

    fieldGuids: parts.filter(part => formFields.find(f => f.guid === part)),
  }

  return res
}

export const FormBuilderUtils = {
  convertToFormField,
  getParsedTableValue,
  sortedByPosition,
  getParsedGroupedInputsValue,
  assignTabIndexes,
  convertFormPages,
  getAddressKeys,
  getRequiredAddressKeys,
  substituteFormulaFields,
}
