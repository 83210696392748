import { Center, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Switch } from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import assert from 'assert'
import { Control, Controller } from 'react-hook-form'
import tinycolor from 'tinycolor2'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { FormField, FormFieldTypes, StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'

interface BooleanFieldProps {
  field: FormField
  control: Control
  error: string
  isDisabled?: boolean
  isReadonly?: boolean
  isLabelHidden?: boolean
  overrideControlName?: string
  style: NonNullable<StyleOptions>
}

export const BooleanField: React.FC<BooleanFieldProps> = ({
  field,
  control,
  error,
  isReadonly,
  isDisabled,
  isLabelHidden,
  overrideControlName,
  style,
}) => {
  assert(field.type === FormFieldTypes.Checkbox || field.type === FormFieldTypes.Switch)

  const { colors } = StyleUtils.getTheme(style)
  const BoolElement = field.type === FormFieldTypes.Checkbox ? Checkbox : Switch
  const fieldId = `${overrideControlName ?? field.key}-bool-input`
  const { getTabIndex } = useFormAccessibility()

  return (
    <Controller
      name={overrideControlName ?? field.key}
      control={control}
      render={({ field: controlField }) => {
        return (
          <>
            <FormControl as={Center} justifyContent="left" isInvalid={!!error} isRequired={field.isRequired} h="100%">
              <Flex alignItems="center" gap="4">
                <BoolElement
                  id={fieldId}
                  sx={{
                    'span.chakra-switch__track[data-checked]': { backgroundColor: colors.primaryColor },
                    'span.chakra-checkbox__control[data-checked]': {
                      backgroundColor: colors.primaryColor,
                      borderColor: colors.primaryColor,
                      '&:hover': {
                        backgroundColor: colors.primaryColor
                          ? tinycolor(colors.primaryColor).darken().toString()
                          : 'blue.600',
                        borderColor: colors.primaryColor
                          ? tinycolor(colors.primaryColor).darken().toString()
                          : 'blue.600',
                      },
                    },
                  }}
                  colorScheme="blue"
                  tabIndex={getTabIndex(field.key)}
                  size={field.type === FormFieldTypes.Checkbox ? 'lg' : 'md'}
                  readOnly={isReadonly}
                  isDisabled={isDisabled}
                  isChecked={controlField.value}
                  onChange={newValue => {
                    controlField.onChange(newValue)
                  }}
                ></BoolElement>
                {!isLabelHidden && (
                  <FormLabel
                    htmlFor={fieldId}
                    mb="0"
                    display="flex"
                    alignItems="center"
                    gap="1"
                    color={colors.labelColor}
                  >
                    {field.name}
                    {field.helperText && <InfoTip tooltipText={field.helperText} />}
                  </FormLabel>
                )}
              </Flex>
              {error && <FormErrorMessage>{error}</FormErrorMessage>}
            </FormControl>
          </>
        )
      }}
    />
  )
}
