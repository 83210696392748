import { Box, Flex, Icon, Progress, Spacer, Tag, Tooltip } from '@chakra-ui/react'
import { DEFAULT_THEME } from '../../constants'
import { ProgressComponentType, Style } from '../../interfaces'
import { LuEye } from 'react-icons/lu'

interface TopNavProps {
  title?: string
  logoUrl?: string
  progress?: number
  style?: Style
  progressComponentType: ProgressComponentType
  isReadOnly?: boolean
}

export const NAVBAR_HEIGHT = '63px'

export const TopNav: React.FC<TopNavProps> = ({
  title,
  logoUrl,
  progress,
  style,
  progressComponentType,
  isReadOnly,
}) => {
  return (
    <Box
      position="sticky"
      top="0px"
      zIndex="docked"
      boxShadow="sm"
      bg="white"
      left="0px"
      right="0px"
      w="100vw"
      className="hide-on-print"
    >
      <Box maxW="7xl" mx="auto" py="3" px={{ base: '2', md: '6', lg: '8' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Flex gap="4" alignItems="center" w="100%">
            <img src={logoUrl ?? 'favicon.ico'} alt={title} style={{ height: '34px' }} />

            <Box fontSize="md" fontWeight="medium">
              {title}
            </Box>
            <Spacer />
            {isReadOnly && (
              <Tooltip label="This form is read-only. You can view the data, but you cannot make any changes.">
                <Tag gap="2">
                  <Icon as={LuEye}></Icon>
                  Read-only
                </Tag>
              </Tooltip>
            )}
          </Flex>
        </Box>
      </Box>
      {progressComponentType === ProgressComponentType.PROGRESS_BAR && (
        <Progress
          value={progress}
          borderRadius="none"
          bg="gray.200"
          height="5px"
          sx={{
            '& > div:first-child': {
              bg: style?.colors?.primaryColor ?? DEFAULT_THEME.colors.primaryColor,
              transitionProperty: 'width',
            },
          }}
        />
      )}
    </Box>
  )
}
