import { Page } from '../interfaces'
import { replace, toNumber } from 'lodash'

const isLoadedFromUrl = (scriptSrc: string | null | undefined): boolean => {
  if (!scriptSrc) {
    return true
  }

  return scriptSrc.includes('bundle.js') || scriptSrc.includes('static/js')
}

const sortPages = (pages: Page[]): Page[] => {
  return pages.sort((a, b) => a.order - b.order)
}

function isMobileBrowser(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor || window['opera']

  return /android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos/i.test(
    userAgent,
  )
}

const isStringUrl = (string: string): boolean => {
  let url: string | URL

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

const getCurrencyValue = (val: string): number => toNumber(replace(val, /,/g, ''))

const getCurrencyString = (val: number): string => val.toLocaleString(undefined, { maximumFractionDigits: 2 })

const yesNoToBoolean = (value: 'Yes' | 'No'): boolean | null => (value === 'Yes' ? true : value === 'No' ? false : null)

export const GeneralUtils = {
  isLoadedFromUrl,
  sortPages,
  isMobileBrowser,
  isStringUrl,
  getCurrencyValue,
  getCurrencyString,
  yesNoToBoolean,
}
