import { Box, Button, Text, useColorModeValue } from '@chakra-ui/react'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

export const DatePickerHeader = (props: ReactDatePickerCustomHeaderProps) => {
  const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } = props
  const buttonColor = useColorModeValue('blackAlpha', 'whiteAlpha')
  const hoverButtonColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.100')

  return (
    <Box
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="ghost"
        colorScheme={buttonColor}
        size="xs"
        onClick={decreaseMonth}
        _hover={{ bg: hoverButtonColor }}
        disabled={prevMonthButtonDisabled}
      >
        <FiChevronLeft />
      </Button>
      <Text flex="1" fontWeight="600" lineHeight="32px">{`${date.toLocaleString('default', {
        month: 'long',
      })} ${date.getFullYear()}`}</Text>
      <Button
        variant="ghost"
        colorScheme={buttonColor}
        size="xs"
        onClick={increaseMonth}
        _hover={{ bg: hoverButtonColor }}
        disabled={nextMonthButtonDisabled}
      >
        <FiChevronRight />
      </Button>
    </Box>
  )
}
