import { Icon, IconButton, Spinner, useColorModeValue } from '@chakra-ui/react'
import { chakraComponents } from 'chakra-react-select'
import { FiChevronDown } from 'react-icons/fi'
import { LuX } from 'react-icons/lu'

interface SelectCustomComponents {
  IndicatorSeparator: React.FC
  DropdownIndicator: React.FC
  LoadingIndicator: React.FC
  ClearIndicator: React.FC
}

export const customSelectComponents: SelectCustomComponents = {
  IndicatorSeparator: () => <></>,
  LoadingIndicator: () => (
    <Spinner
      emptyColor={useColorModeValue('gray.200', 'gray.600')}
      color={useColorModeValue('blue.500', 'blue.200')}
      size="xs"
    />
  ),
  ClearIndicator: (props: any) => (
    <chakraComponents.ClearIndicator {...props}>
      <Icon as={LuX} w={4} h={4} color="gray.500" />
    </chakraComponents.ClearIndicator>
  ),
  DropdownIndicator: () => (
    <IconButton
      w="28px"
      minW="unset"
      tabIndex={-1}
      aria-label="more options"
      bg="none"
      _hover={{ bg: 'none' }}
      colorScheme="whiteAlpha"
      size="xs"
      icon={<Icon as={FiChevronDown} w={4} h={4} color="gray.500" />}
    />
  ),
}
