import { Flex, Icon, Text, VStack } from '@chakra-ui/react'
import { LuLock } from 'react-icons/lu'

export const UnauthorizedPage: React.FC = () => {
  return (
    <Flex w="100vw" h="100vh" bg="gray.100" alignItems="center" justifyContent="center">
      <VStack>
        <Icon as={LuLock} boxSize={10} color="gray.400" />
        <Text fontSize="xl" fontWeight="semibold" color="gray.600">
          Invalid form access.
        </Text>
        <Text fontSize="xs" color="gray.500">
          You do not have access to this form. Please contact the form owner for details.
        </Text>
      </VStack>
    </Flex>
  )
}
