import { Table, Td, Th, Thead, Tbody, Tr, Code } from '@chakra-ui/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export const MarkdownPreview: React.FC<{ content: string }> = ({ content }) => {
  const elementsWithClassName = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'a',
    'em',
    'strong',
    'del',
    'code',
    'pre',
    'blockquote',
    'ul',
    'ol',
    'li',
    'hr',
    'br',
    'img',
  ]
  const getCustomComponent = (elementName: string) => (props: any) =>
    React.createElement(elementName, { ...props, className: 'no-chakra' })

  const components = elementsWithClassName.reduce((acc: any, elementName) => {
    acc[elementName] = getCustomComponent(elementName)

    // use the chakra coponents for table stuff
    acc['table'] = Table
    acc['thead'] = Thead
    acc['tbody'] = Tbody
    acc['tr'] = Tr
    acc['th'] = Th
    acc['td'] = Td
    acc['code'] = Code

    return acc
  }, {})

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
      {content}
    </ReactMarkdown>
  )
}
