import { Icon, IconProps, Image } from '@chakra-ui/react'
import * as FcIcons from 'react-icons/fc'
import * as LuIcons from 'react-icons/lu'
import { GeneralUtils } from '../../utils'

interface DynamicIconProps extends IconProps {
  icon: string
}

export const DynamicIcon = ({ icon, ...props }: DynamicIconProps) => {
  if (GeneralUtils.isStringUrl(icon)) {
    return <Image src={icon} width={props.boxSize} />
  }

  const type = icon.slice(0, 2)

  let IconComponent

  switch (type) {
    case 'Lu':
      IconComponent = LuIcons[icon as keyof typeof LuIcons]
      break
    case 'Fc':
      IconComponent = FcIcons[icon as keyof typeof FcIcons]
      break
    default:
      IconComponent = LuIcons.LuAlertCircle
  }

  return <Icon as={IconComponent} {...props} />
}
