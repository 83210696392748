import React, { useEffect } from 'react'
import Editor, { EditorProps, useMonaco } from '@monaco-editor/react'
import { theme, useColorMode } from '@chakra-ui/react'

export enum CodeLanguage {
  JS = 'javascript',
  HTML = 'html',
  PYTHON = 'python',
  JSON = 'json',
  MD = 'md',
}

export const parseCodeInputValue = (value: unknown): string =>
  typeof value !== 'string' && value !== null ? JSON.stringify(value, null, '\t') : value ?? ''

export const parseCodeOutputValue = (value?: string, language?: CodeLanguage): any => {
  if (value && language === 'json') {
    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  }
  return value
}

export const isCodeValid = (value: string, language: CodeLanguage): boolean => {
  if (language === CodeLanguage.JSON) {
    try {
      JSON.parse(value)
      return true
    } catch (e) {
      return false
    }
  }

  return true
}

export const CodeEditor = (props: EditorProps): React.ReactElement => {
  const { colorMode } = useColorMode()
  const monaco = useMonaco()
  const isDarkMode = colorMode === 'dark'

  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme('swarm-code-editor-dark', {
        base: 'vs-dark',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': theme.colors.gray[900],
        },
      })

      monaco.editor.defineTheme('swarm-code-editor', {
        base: 'vs',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': theme.colors.white,
        },
      })

      if (isDarkMode) {
        monaco.editor.setTheme('swarm-code-editor-dark')
      } else {
        monaco.editor.setTheme('swarm-code-editor')
      }
    }
  }, [monaco, colorMode])

  return <Editor {...props} theme={isDarkMode ? 'swarm-code-editor-dark' : 'swarm-code-editor'} />
}
