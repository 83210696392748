import {
  differenceInSeconds,
  endOfDay,
  format,
  formatDistanceToNowStrict,
  formatDuration,
  formatISO,
  intervalToDuration,
  isBefore,
  isToday,
  isValid,
  isWithinInterval,
  parse,
  startOfDay,
  startOfHour,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'
import { enAU } from 'date-fns/locale'

export const timeFramesList: string[] = ['1 day', '1 week', '1 month']

function columnDateTime(someDate: Date | string): string {
  const someDateObject = new Date(someDate)
  if (isToday(someDateObject)) {
    return format(someDateObject, 'p')
  }
  return format(someDateObject, 'd MMM yy')
}

function secondsToFriendlyTime(diffTimeSeconds: number): string {
  const minutes = Math.floor(diffTimeSeconds / 60)
  const seconds = diffTimeSeconds - minutes * 60
  if (minutes > 0) {
    return `${minutes}min ${seconds.toFixed()}s`
  }
  return `${diffTimeSeconds.toFixed(2)}s`
}

function columnDuration(someDate1: Date | string, someDate2: Date | string): string {
  const diffTimeSeconds = differenceInSeconds(new Date(someDate2), new Date(someDate1))
  return secondsToFriendlyTime(diffTimeSeconds)
}

function friendlyDateTime(someDate: Date | string): string | null {
  const someDateObject = new Date(someDate)
  if (isValid(someDateObject)) {
    if (isBefore(someDateObject, subDays(new Date(), 1))) {
      return format(someDateObject, 'PPp', { locale: enAU })
    }
    return `${formatDistanceToNowStrict(someDateObject)} ago`
  }
  return null
}

function friendlyDate(someDate: Date | string): string {
  return format(new Date(someDate), 'd MMM yy')
}

function convertTimeToSeconds(someTime: string): number {
  const a = someTime.split(':')
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
  return seconds
}

function parseDateValidator(
  date: string,
  minDate: Date = new Date(1900, 1, 1),
  maxDate: Date = new Date(2100, 12, 31),
): Date {
  const parsedDate = parse(date, 'dd/MM/yyyy', new Date())

  if (isValid(parsedDate)) {
    return isWithinInterval(parsedDate, { start: minDate, end: maxDate }) ? parsedDate : new Date()
  }

  return new Date()
}

function formatDate(date: Date): string {
  return format(date, 'dd/MM/yyyy')
}

function formatDateWithTime(date: Date): string {
  return format(date, 'dd/MM/yyyy HH:mm:ss')
}

function formatSecondsAsDuration(seconds: number): string {
  if (seconds < 1.0) {
    return `${seconds.toFixed(2)} seconds`
  }
  return formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }))
}

function formatFullDateTime(date: Date): string {
  return format(date, 'dd MMMM yyyy, h:mm a')
}

interface DateRangeShortcut {
  label: string
  dateRange: [Date, Date]
  includeTime: boolean
}

export const DATE_RANGE_SHORTCUTS: DateRangeShortcut[] = [
  {
    label: 'Today',
    dateRange: [startOfDay(new Date()), endOfDay(new Date())],
    includeTime: true,
  },
  {
    label: 'Past week',
    dateRange: [subDays(startOfDay(new Date()), 7), endOfDay(new Date())],
    includeTime: true,
  },
  {
    label: 'Past month',
    dateRange: [subMonths(startOfDay(new Date()), 1), endOfDay(new Date())],
    includeTime: true,
  },
  {
    label: 'Past 3 months',
    dateRange: [subMonths(startOfDay(new Date()), 3), endOfDay(new Date())],
    includeTime: true,
  },
]

function getFilterDates(timeFrame: string): [string, string] {
  let end: Date

  if (timeFrame === '1 day') {
    end = startOfHour(new Date())
    end.setHours(end.getHours() + 1)
  } else {
    end = endOfDay(new Date())
  }

  const start = timeFrame.includes('day')
    ? subDays(end, +timeFrame.match(/\d+/)![0])
    : timeFrame.includes('week')
    ? subWeeks(end, +timeFrame.match(/\d+/)![0])
    : subMonths(end, +timeFrame.match(/\d+/)![0])

  return [formatISO(start), formatISO(end)]
}

const isValidDateFormat = (dateString: string): boolean => {
  // Checks for format: dd/MM/yyyy
  const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
  return regex.test(dateString)
}

export const DatetimeUtils = {
  columnDateTime,
  secondsToFriendlyTime,
  columnDuration,
  friendlyDateTime,
  friendlyDate,
  convertTimeToSeconds,
  parseDateValidator,
  formatDate,
  formatDateWithTime,
  formatSecondsAsDuration,
  formatFullDateTime,
  getFilterDates,
  isValidDateFormat,
}
