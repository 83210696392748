import { ColorModeScript } from '@chakra-ui/react'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { UnmandFormComponent } from './interfaces/unmand-form-component'
import { EMPTY_FN } from './constants'
import { GeneralUtils } from './utils'

declare global {
  interface Window {
    unmandForm: UnmandFormComponent
    loadUnmandForm: (targetContainerId: string) => UnmandFormComponent
    google: any
  }
}

const unmandFormScript = document.currentScript

window.loadUnmandForm = (containerId: string) => {
  const container = document.getElementById(containerId)
  if (!container) throw new Error('Failed to find the root element')
  const root = ReactDOM.createRoot(container)

  const unmandForm: UnmandFormComponent = {
    getData: EMPTY_FN,
    destroy: () => {
      const scriptElement = unmandFormScript as HTMLScriptElement
      if (scriptElement && scriptElement.parentNode) {
        scriptElement.parentNode.removeChild(scriptElement)
      }
    },
  }

  root.render(
    <React.StrictMode>
      <ColorModeScript />
      <App unmandForm={unmandForm} scriptReference={unmandFormScript} />
    </React.StrictMode>,
  )
  reportWebVitals()

  return unmandForm
}

if (GeneralUtils.isLoadedFromUrl(unmandFormScript?.getAttribute('src')) && typeof window !== 'undefined') {
  window.unmandForm = window.loadUnmandForm('root')
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
