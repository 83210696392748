import { FormField, FormFieldTypes, FormResponse, Page } from '../interfaces'
import { FormRendererUtils } from './form-renderer.utils'

const parseFormResponse = (response: FormResponse): FormResponse => {
  response.pages.forEach(page => {
    if (!page.isGrouped) {
      return
    }

    const pageFields = response.layout[page.guid]
    pageFields.forEach(field => {
      if (field.jsonpath) {
        field.jsonpath = FormRendererUtils.prependParentJsonpath(page.parentJsonpath, field.jsonpath)
        field.listIndex = 0

        if (field.component === FormFieldTypes.Address) {
          field.config.streetAddressJsonPath = FormRendererUtils.prependParentJsonpath(
            page.parentJsonpath,
            field.config.streetAddressJsonPath,
          )
          field.config.cityJsonPath = FormRendererUtils.prependParentJsonpath(
            page.parentJsonpath,
            field.config.cityJsonPath,
          )
          field.config.countryJsonPath = FormRendererUtils.prependParentJsonpath(
            page.parentJsonpath,
            field.config.countryJsonPath,
          )
          field.config.postalCodeJsonPath = FormRendererUtils.prependParentJsonpath(
            page.parentJsonpath,
            field.config.postalCodeJsonPath,
          )
          field.config.stateProvinceJsonPath = FormRendererUtils.prependParentJsonpath(
            page.parentJsonpath,
            field.config.stateProvinceJsonPath,
          )
          field.config.streetAddressLine2JsonPath = FormRendererUtils.prependParentJsonpath(
            page.parentJsonpath,
            field.config.streetAddressLine2JsonPath,
          )
        }
      }
    })
  })
  return response
}

const getListIndexCount = (formFields: FormField[]): number => {
  const uniqueListIndices = new Set<number>()

  formFields?.forEach(field => {
    if (field.listIndex !== undefined) {
      uniqueListIndices.add(field.listIndex)
    }
  })

  return uniqueListIndices.size ?? 0
}

const getTabLabel = (
  page: Page,
  index: number,
  formFields: FormField[],
  getValuesFn: (name: string) => any,
): string => {
  let label = (page?.tabLabel ?? 'Entry') + ' ' + (index + 1)

  page?.tabLabelFieldIds?.map(tabLabelFieldId => {
    const tabFields = formFields.filter(f => f.listIndex === index)

    const field =
      tabFields.find(f => f.key === tabLabelFieldId) ?? tabFields.find(f => f.baseFieldId === tabLabelFieldId)

    let fieldLabel = ''

    if (field) {
      const fieldValue = getValuesFn(field.key)
      fieldLabel = fieldValue ? ` - ${fieldValue}` : ''
    }

    label += fieldLabel
  })

  return label
}

export const GroupedPageUtils = {
  parseFormResponse,
  getListIndexCount,
  getTabLabel,
}
