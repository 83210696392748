import React, { useState, useCallback, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react'

export type ConfirmationDialogTypes = {
  showConfirmDialog: (confirmCallback: () => void) => void
  RenderDialog: RenderDialogFunction
}
type RenderDialogFunction = () => JSX.Element
type ConfirmationDialogProps = {
  title: string
  message: React.ReactNode
  primaryBtnLabel: string
  secondaryBtnLabel: string
}

export function useConfirmationDialog({
  title,
  message,
  primaryBtnLabel,
  secondaryBtnLabel,
}: ConfirmationDialogProps): ConfirmationDialogTypes {
  const [isOpen, setIsOpen] = useState(false)
  const [onConfirm, setOnConfirm] = useState<null | (() => void)>(null)
  const cancelRef = useRef(null)

  const closeDialog = useCallback(() => {
    setIsOpen(false)
    setOnConfirm(null)
  }, [])

  const showConfirmDialog = useCallback((confirmCallback: () => void) => {
    setIsOpen(true)
    setOnConfirm(() => confirmCallback)
  }, [])

  const confirm = useCallback(() => {
    if (onConfirm) onConfirm()
    closeDialog()
  }, [closeDialog, onConfirm])

  const RenderDialog: RenderDialogFunction = () => (
    <AlertDialog isOpen={isOpen} onClose={closeDialog} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={closeDialog} mr={3} variant="ghost" colorScheme="gray">
              {secondaryBtnLabel}
            </Button>
            <Button colorScheme="red" onClick={confirm}>
              {primaryBtnLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )

  return { showConfirmDialog, RenderDialog }
}
