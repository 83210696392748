import React from 'react'
import { Box, VStack, Text, Card, Icon, Button } from '@chakra-ui/react'
import { LuSave, LuCopy, LuCheck } from 'react-icons/lu'

export const SavedProgressPage: React.FC<{ guid: string }> = ({ guid }) => {
  const [copied, setCopied] = React.useState(false)

  const getSavedProgressUrl = () => {
    const url = new URL(window.location.origin + window.location.pathname)
    url.searchParams.set('saved-progress', guid)
    return url.toString()
  }

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(getSavedProgressUrl())
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }

  const SavedMessage = (
    <Box className="hide-on-print">
      <Card variant="outline" p="10" bg="green.50" my="6">
        <VStack spacing={4}>
          <Icon as={LuSave} boxSize={15} color="green.500" />
          <Text fontSize="xl" fontWeight="semibold" color="green.500">
            Submission saved as draft
          </Text>
          <Text fontSize="sm" color="gray.500">
            Paste this link to continue later
          </Text>
          <Button
            leftIcon={<Icon as={copied ? LuCheck : LuCopy} />}
            colorScheme={copied ? 'green' : 'gray'}
            variant="solid"
            onClick={handleCopyLink}
            size="sm"
          >
            {copied ? 'Copied!' : 'Copy Link'}
          </Button>
        </VStack>
      </Card>
    </Box>
  )

  return (
    <VStack
      flex="1"
      bg="gray.100"
      alignItems="center"
      justifyContent="center"
      gap={6}
      h="100%"
      className="hide-on-print"
    >
      {SavedMessage}
    </VStack>
  )
}

export default SavedProgressPage
