import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  UseRadioProps,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import assert from 'assert'
import React, { LegacyRef } from 'react'
import { Control, Controller } from 'react-hook-form'
import tinycolor from 'tinycolor2'
import { DEFAULT_THEME } from '../../constants'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { FormField, FormFieldTypes, StyleOptions } from '../../interfaces'
import { GeneralUtils, StyleUtils } from '../../utils'

interface YesNoFieldProps {
  field: FormField
  control: Control
  error: string
  isDisabled?: boolean
  overrideControlName?: string
  style: NonNullable<StyleOptions>
}

export const YES_NO_OPTIONS = ['Yes', 'No'] as const

export const YesNoField = ({ field, control, error, isDisabled, overrideControlName, style }: YesNoFieldProps) => {
  assert(field.type === FormFieldTypes.YesNo)

  const { colors, inputs } = StyleUtils.getTheme(style)

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field.name,
  })

  const group = getRootProps()
  const { getTabIndex } = useFormAccessibility()

  return (
    <Controller
      name={overrideControlName ?? field.key}
      control={control}
      render={({ field: controlField }) => (
        <FormControl isRequired={field.isRequired} isInvalid={!!error}>
          <FormLabel display="flex" alignItems="center" gap="1" color={colors.labelColor}>
            {field.name}
            <span className="form__optional-indicator">
              {!field.isRequired && inputs.hideRequiredAsterisk && '- Optional'}
            </span>
            {field.helperText && <InfoTip tooltipText={field.helperText} />}
          </FormLabel>
          <HStack {...group}>
            {YES_NO_OPTIONS.map(value => {
              const radio = getRadioProps({ value: GeneralUtils.yesNoToBoolean(value) })
              return (
                <OptionButton
                  id={`unmand-input-option-${overrideControlName ?? field.guid}`}
                  {...radio}
                  ref={controlField.ref}
                  key={value}
                  tabIndex={getTabIndex(controlField.name)}
                  sx={StyleUtils.getTheme(style)}
                  isChecked={GeneralUtils.yesNoToBoolean(value) === controlField.value}
                  onChange={() => controlField.onChange(GeneralUtils.yesNoToBoolean(value))}
                  isDisabled={field.isReadonly || isDisabled}
                >
                  {value}
                </OptionButton>
              )
            })}
          </HStack>
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
      )}
    />
  )
}

interface OptionButtonProps extends UseRadioProps {
  sx: typeof DEFAULT_THEME
  children: React.ReactNode
  tabIndex: number
}

const OptionButton = React.forwardRef(({ sx, tabIndex, ...props }: OptionButtonProps, ref) => {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label">
      <input {...input} tabIndex={tabIndex} ref={ref as LegacyRef<HTMLInputElement>} />
      <Box
        {...checkbox}
        cursor="pointer"
        fontWeight="semibold"
        className="yes-no-option"
        borderRadius={sx.buttons.borderRadius}
        borderWidth="2px"
        borderColor={tinycolor(sx.layout.backgroundColor).isLight() ? 'blackAlpha.300' : 'whiteAlpha.300'}
        opacity={0.8}
        fontSize="md"
        _focus={{
          boxShadow: `0 0 0 3px ${tinycolor(sx.colors.primaryColor).setAlpha(0.6).toString()}`,
        }}
        _checked={{
          opacity: 1,
          ...StyleUtils.getSolidButtonStyle(sx.colors.primaryColor, sx.colors.buttonTextColor),
        }}
        px={6}
        py={2.5}
        _disabled={{
          opacity: 0.5,
          cursor: 'not-allowed',
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
})
