import { Card, Flex, Icon, Text, VStack } from '@chakra-ui/react'
import { LuAlertTriangle } from 'react-icons/lu'

export const ErrorPage: React.FC<{ errorMsg: string }> = ({ errorMsg }) => {
  return (
    <Flex w="100vw" h="100vh" bg="gray.100" alignItems="center" justifyContent="center">
      <Card variant="outline" p="10" bg="red.50">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} color="red.500" />
          <Text fontSize="xl" fontWeight="semibold" color="red.500">
            Something went wrong
          </Text>
          <Text fontSize="sm" color="gray.500">
            {errorMsg}
          </Text>
        </VStack>
      </Card>
    </Flex>
  )
}
