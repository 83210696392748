export const collectStyles = (containerId: string): string => {
  let css = collectInlineStylesAndStyleTags(`#${containerId}`)
  css += collectStylesFromExternalStylesheets(`#${containerId}`)

  return css
}

const collectInlineStylesAndStyleTags = (containerSelector: string): string => {
  let css = ''
  const container = document.querySelector(containerSelector)
  if (container) {
    container.querySelectorAll('*').forEach(element => {
      const style = element.getAttribute('style')
      if (style) {
        const className =
          element.className && typeof element.className === 'string' ? `.${element.className.split(' ').join('.')}` : ''
        const selector = element.tagName.toLowerCase() + (element.id ? `#${element.id}` : '') + className
        css += `${selector} { ${style} }\n`
      }
    })

    container.querySelectorAll('style').forEach(styleTag => {
      css += styleTag.innerHTML + '\n'
    })
  }
  return css
}

const collectStylesFromExternalStylesheets = (containerSelector: string): string => {
  let css = ''
  const container = document.querySelector(containerSelector)
  if (container) {
    const sheets = document.styleSheets
    for (let i = 0; i < sheets.length; i++) {
      try {
        const rules = sheets[i].cssRules
        for (let j = 0; j < rules.length; j++) {
          const rule = rules[j]
          if (rule instanceof CSSStyleRule && container.querySelector(rule.selectorText)) {
            css += rule.cssText + '\n'
          }
        }
      } catch (e) {
        // Handle CORS errors from restricted stylesheets
        console.warn('Cannot access stylesheet: ', sheets[i].href)
      }
    }
  }
  return css
}
