import { theme } from '@chakra-ui/react'

export const DEFAULT_THEME = {
  layout: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    padding: 10,
  },
  colors: {
    primaryColor: theme.colors.blue[500], // button color, input focus border color, progress bar color
    buttonTextColor: theme.colors.white,
    inputBgColor: theme.colors.white,
    inputTextColor: theme.colors.gray[900],
    labelColor: theme.colors.gray[900],
    errorColor: theme.colors.red[500],
    dividerColor: theme.colors.gray[200],
  },
  buttons: {
    size: 'md',
    borderRadius: 6,
    border: false,
    borderWidth: 1,
    borderColor: theme.colors.blue[500],
  },
  inputs: {
    variant: 'outline',
    borderWidth: 1,
    borderColor: theme.colors.gray[200],
    borderRadius: 6,
    hideRequiredAsterisk: false,
  },
  text: {
    fontFamily: 'Inter',
    fontSize: 'sm',
  },
}
