import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import tinycolor from 'tinycolor2'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'

interface SliderFieldProps {
  formControlName: string
  label: string
  labelTooltip?: string
  control: Control
  error?: string
  isRequired?: boolean
  isLabelHidden?: boolean
  isDisabled?: boolean
  isReadonly?: boolean
  helperText?: string
  step?: number
  min?: number
  max?: number
  prefix?: string
  suffix?: string
  overrideControlName?: string
  style: NonNullable<StyleOptions>
  restrictedValues?: number[]
}

export const SliderField: React.FC<SliderFieldProps> = ({
  formControlName,
  labelTooltip,
  label,
  control,
  error,
  helperText,
  isRequired,
  isLabelHidden,
  isDisabled,
  isReadonly,
  step,
  min,
  max,
  prefix,
  suffix,
  overrideControlName,
  restrictedValues,
  style,
}) => {
  const { colors, inputs } = StyleUtils.getTheme(style)
  const { getTabIndex } = useFormAccessibility()
  const [showTooltip, setShowTooltip] = useState(isReadonly ? isReadonly : false)

  return (
    <Controller
      name={overrideControlName ?? formControlName}
      control={control}
      render={({ field }) => {
        const displayedValue = `${prefix ?? ''}${field?.value?.toLocaleString() ?? '0'}${suffix ?? ''}`

        return (
          <FormControl isInvalid={!!error} isRequired={isRequired}>
            {!isLabelHidden && (
              <FormLabel display="flex" alignItems="center" gap="1" color={colors?.labelColor}>
                <Tooltip label={labelTooltip} placement="top-start">
                  <div>
                    {label}
                    <Text className="form__optional-indicator">
                      {!isRequired && inputs?.hideRequiredAsterisk && '- Optional'}
                    </Text>
                  </div>
                </Tooltip>
                {helperText && <InfoTip tooltipText={helperText} />}
              </FormLabel>
            )}

            <HStack gap={8}>
              <Slider
                id={`unmand-input-${overrideControlName ?? formControlName}`}
                tabIndex={getTabIndex(formControlName)}
                aria-label={`slider-${formControlName}`}
                {...field}
                sx={{
                  '& .chakra-slider__track': {
                    height: '6px',
                    borderRadius: 'full',
                  },
                }}
                step={step ?? 1}
                min={restrictedValues?.[0] ?? min ?? undefined}
                max={restrictedValues?.[restrictedValues?.length - 1] ?? max ?? undefined}
                isDisabled={isDisabled || isReadonly}
                value={field.value}
                onChange={value => {
                  const closestValue = restrictedValues?.reduce((prev, curr) =>
                    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev,
                  )
                  field.onChange(closestValue ?? value)
                }}
                onChangeEnd={() => setShowTooltip(false)}
                onChangeStart={() => setShowTooltip(true)}
              >
                {restrictedValues?.map((value, index) => (
                  <SliderMark
                    key={index}
                    value={value}
                    fontSize="2xs"
                    transform="translate(-50%, 10px)"
                    fontWeight="500"
                    color={'gray.500'}
                  >
                    {value.toLocaleString()}
                  </SliderMark>
                ))}
                <SliderTrack>
                  <SliderFilledTrack bg={colors?.primaryColor} />
                </SliderTrack>
                <Tooltip hasArrow isOpen={showTooltip} label={displayedValue}>
                  <SliderThumb
                    _focus={{
                      boxShadow: `0 0 0 3px ${tinycolor(colors?.primaryColor).setAlpha(0.6).toString()}`,
                    }}
                  />
                </Tooltip>
              </Slider>
              <Input isDisabled value={displayedValue} w="100px" size="xs" />
            </HStack>
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
        )
      }}
    />
  )
}
