import { ButtonProps, theme } from '@chakra-ui/react'
import tinycolor from 'tinycolor2'
import { DEFAULT_THEME } from '../constants'
import { StyleOptions } from '../interfaces'

const toPixels = (value?: number) => {
  return value ? `${value}px` : undefined
}

const getPlaceholderColor = (color?: string) => {
  return color
    ? tinycolor(color).isLight()
      ? tinycolor(color).darken(20).toString()
      : tinycolor(color).lighten(25).setAlpha(0.9).toString()
    : theme.colors.gray[300]
}

const getIconColor = (color?: string) => {
  return color
    ? tinycolor(color).isLight()
      ? tinycolor(color).darken(25).toString()
      : tinycolor(color).lighten(30).toString()
    : theme.colors.gray[400]
}

const getSolidButtonStyle = (primaryColor?: string, buttonTextColor?: string): ButtonProps => {
  return {
    bg: primaryColor,
    borderColor: primaryColor,
    color: buttonTextColor,
    _hover: {
      bg: primaryColor ? tinycolor(primaryColor).darken(10).toString() : 'blue.600',
      borderColor: primaryColor ? tinycolor(primaryColor).darken(10).toString() : 'blue.600',
    },
    _active: {
      bg: primaryColor ? tinycolor(primaryColor).darken(15).toString() : 'blue.700',
      borderColor: primaryColor ? tinycolor(primaryColor).darken(15).toString() : 'blue.700',
    },
  }
}

const getGhostButtonStyle = (primaryColor?: string): ButtonProps => {
  return {
    color: primaryColor
      ? tinycolor(primaryColor).isLight()
        ? tinycolor(primaryColor).darken().setAlpha(0.7).toString()
        : tinycolor(primaryColor).lighten().setAlpha(0.7).toString()
      : 'blue.600',
    bg: 'transparent',
    _hover: {
      bg: primaryColor ? tinycolor(primaryColor).setAlpha(0.1).toString() : undefined,
    },
    _active: {
      color: primaryColor,
      bg: primaryColor ? tinycolor(primaryColor).setAlpha(0.1).toString() : 'blue.dark.100',
    },
  }
}

const getSubtleButtonStyle = (primaryColor?: string): ButtonProps => {
  return {
    color: primaryColor ? primaryColor : 'blue.600',
    bg: primaryColor ? tinycolor(primaryColor).lighten(20).setAlpha(0.2).toString() : 'blue.50',
    _hover: {
      bg: primaryColor ? tinycolor(primaryColor).lighten(20).setAlpha(0.35).toString() : undefined,
    },
    _active: {
      color: primaryColor,
      bg: primaryColor ? tinycolor(primaryColor).lighten(20).setAlpha(0.4).toString() : 'blue.dark.100',
    },
  }
}

function getTheme(style: StyleOptions): typeof DEFAULT_THEME {
  const { colors: themeColors, inputs: themeInputs, buttons: themeButtons } = style

  const applyColors = () => ({
    primaryColor: themeColors?.primaryColor ?? DEFAULT_THEME.colors.primaryColor,
    inputBgColor: themeColors?.inputBgColor ?? DEFAULT_THEME.colors.inputBgColor,
    inputTextColor: themeColors?.inputTextColor ?? DEFAULT_THEME.colors.inputTextColor,
    labelColor: themeColors?.labelColor ?? DEFAULT_THEME.colors.labelColor,
    dividerColor: themeColors?.dividerColor ?? DEFAULT_THEME.colors.dividerColor,
    buttonTextColor: themeColors?.buttonTextColor ?? DEFAULT_THEME.colors.buttonTextColor,
    errorColor: themeColors?.errorColor ?? DEFAULT_THEME.colors.errorColor,
  })

  const applyInputs = () => ({
    borderWidth: themeInputs?.borderWidth ?? DEFAULT_THEME.inputs.borderWidth,
    borderRadius: themeInputs?.borderRadius ?? DEFAULT_THEME.inputs.borderRadius,
    borderColor: themeInputs?.borderColor ?? DEFAULT_THEME.inputs.borderColor,
    variant: themeInputs?.variant ?? DEFAULT_THEME.inputs.variant,
    hideRequiredAsterisk: themeInputs?.hideRequiredAsterisk ?? DEFAULT_THEME.inputs.hideRequiredAsterisk,
  })

  const applyButtons = () => ({
    size: themeButtons?.size ?? DEFAULT_THEME.buttons.size,
    borderRadius: themeButtons?.borderRadius ?? DEFAULT_THEME.buttons.borderRadius,
    border: themeButtons?.border ?? DEFAULT_THEME.buttons.border,
    borderWidth: themeButtons?.borderWidth ?? DEFAULT_THEME.buttons.borderWidth,
    borderColor: themeButtons?.borderColor ?? DEFAULT_THEME.buttons.borderColor,
  })

  const applyLayout = () => ({
    backgroundColor: style.layout?.backgroundColor ?? DEFAULT_THEME.layout.backgroundColor,
    borderRadius: style.layout?.borderRadius ?? DEFAULT_THEME.layout.borderRadius,
    padding: style.layout?.padding ?? DEFAULT_THEME.layout.padding,
  })

  const applyText = () => ({
    fontFamily: style.text?.fontFamily ?? DEFAULT_THEME.text.fontFamily,
    fontSize: style.text?.fontSize ?? DEFAULT_THEME.text.fontSize,
  })

  return {
    colors: applyColors(),
    inputs: applyInputs(),
    buttons: applyButtons(),
    layout: applyLayout(),
    text: applyText(),
  }
}

export const StyleUtils = {
  toPixels,
  getPlaceholderColor,
  getIconColor,
  getSolidButtonStyle,
  getGhostButtonStyle,
  getSubtleButtonStyle,
  getTheme,
}
