import { isEmpty, isNil, keys } from 'lodash'

interface CallApiParams {
  baseUrl: string
  path?: string
  request: RequestInit
  params?: { [key: string]: string | number | boolean | undefined }
  parseResponse?: boolean
}

export const callApi = async <T>({
  baseUrl,
  path = '',
  request,
  params = {},
  parseResponse = true,
}: CallApiParams): Promise<T> => {
  const url = path !== '' || params ? `${baseUrl}${createPathWithParams(path, params)}` : baseUrl

  const response = await fetch(url, request)

  if (!response.ok) {
    /**
     * Possible error codes:
     * 418 - Invalid password
     * 410 - Form is disabled
     * 401 - Unauthorized (e.g. no auth token provided)
     * 404 - Not found
     * 400 - Bad request (e.g. no form data submitted)
     */
    throw {
      status: response.status,
      statusText: response.statusText,
      ...(await response.json()),
    }
  }

  let returnValue

  try {
    if (parseResponse) {
      const contentType = response.headers.get('Content-Type')
      if (contentType?.toLowerCase().includes('application/octet-stream')) {
        returnValue = await response.blob()
      } else {
        returnValue = (await response.json()) as T
      }
    } else {
      returnValue = (await response.text()) as unknown as T
    }
  } catch (e) {
    returnValue = null as unknown as T
  }

  return returnValue
}

const createPathWithParams = (
  path: string,
  params: { [key: string]: string | number | boolean | undefined },
): string => {
  return isEmpty(params)
    ? path
    : `${path}?${keys(params)
        .filter((k: any) => !isNil(params[k]))
        .map((k: any) => `${k}=${encodeURIComponent(params[k]!)}`)
        .join('&')}`
}
