import { Box, Flex, Heading, Text } from '@chakra-ui/react'

export const NotFound: React.FC = () => {
  return (
    <Flex w="100vw" h="100vh" bg="gray.100" alignItems="center" justifyContent="center">
      <Flex direction={{ base: 'column', md: 'row' }}>
        <Text fontSize={{ base: '4xl', md: '5xl' }} fontWeight="bold" color="brand.500">
          404
        </Text>
        <Box ml={{ base: 0, md: 6 }}>
          <Box borderLeftWidth={{ base: 0, md: '1px' }} pl={{ base: 0, md: 6 }}>
            <Heading as="h1" fontWeight="bold" fontSize={{ base: '4xl', md: '5xl' }}>
              Form not found
            </Heading>
            <Text color="subtle">This form does not exist, please double check the form's script or url</Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}
