import { Button, Tooltip, useToast } from '@chakra-ui/react'
import jsonpath from 'jsonpath'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useFormState } from '../../context/FormStateContext'
import { APILookupField, StyleOptions } from '../../interfaces'
import { callApi } from '../../services'
import { StyleUtils } from '../../utils'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'

interface ApiLookupFieldProps {
  field: APILookupField
  formControlName: string
  label: string
  control: Control
  error?: string | undefined
  isDisabled?: boolean
  helperText?: string
  overrideControlName?: string
  style: NonNullable<StyleOptions>
}

export const ApiLookupField: React.FC<ApiLookupFieldProps> = ({
  field,
  formControlName,
  label,
  control,
  helperText,
  overrideControlName,
  style,
}) => {
  const { colors } = style
  const {
    formMethods: { getValues, setValue },
  } = useFormState()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { getTabIndex } = useFormAccessibility()

  const onTriggerApiLookup = async () => {
    setIsLoading(true)
    const queryParams = {}
    field.queryParams.forEach(param => {
      queryParams[param.queryParamKey] = getValues(param.targetFormFieldId)
    })

    try {
      const response = await callApi<any>({
        baseUrl: field.apiUrl,
        parseResponse: true,
        request: {
          method: 'POST',
          headers: {
            ...JSON.parse(field.apiRequestHeaders || '{}'),
          },
        },
        params: queryParams,
      })

      if (response?.status === 404 && response?.msg) {
        toast({
          title: 'Failed to fetch',
          description: response.msg,
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 4000,
        })
        setIsLoading(false)
        return
      }

      field.lookupMapping.forEach(mapping => {
        const queryValue = jsonpath.query(response, mapping.sourceJsonpath)

        if (queryValue.length > 0) {
          setValue(mapping.targetFormFieldId, queryValue[0])
        }
      })
      setIsLoading(false)
    } catch (e) {
      toast({
        title: 'Failed to fetch',
        description: 'Please double check your input values.',
        status: 'error',
        isClosable: true,
        duration: 4000,
      })
      setIsLoading(false)
    }
  }

  return (
    <Controller
      name={overrideControlName ?? formControlName}
      control={control}
      render={() => {
        return (
          <Tooltip label={helperText}>
            <Button
              mt="6"
              id={`unmand-api-lookup-${overrideControlName ?? formControlName}`}
              tabIndex={getTabIndex(formControlName)}
              aria-label="Lookup"
              isDisabled={field?.queryParams?.some(param => !getValues(param.targetFormFieldId))}
              width="100%"
              onClick={onTriggerApiLookup}
              {...StyleUtils.getSolidButtonStyle(colors?.primaryColor, colors?.buttonTextColor)}
              isLoading={isLoading}
            >
              {label}
            </Button>
          </Tooltip>
        )
      }}
    />
  )
}
